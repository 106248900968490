import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import {
  AppBar,
  Box,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core"

import MuiTableCell from "@material-ui/core/TableCell"
import StickyFooter from "../components/sticky-footer"

import firebase from "gatsby-plugin-firebase"

import ImgOrderConfirmed from "../images/order_status_confirmed.png"
import ImgOrderInPrep from "../images/order_status_inprep.png"
import ImgOrderDelivery from "../images/order_status_delivery.png"
import ImgOrderDone from "../images/order_status_done.png"
import { OrderStatus } from "../interfaces/order"
import PriceText from "../components/price-text"

const TableCell = withStyles(theme => ({
  root: {
    borderBottom: "none",
    padding: 0,
    paddingBottom: theme.spacing(1),
  },
}))(MuiTableCell)

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    flex: 1,
    textAlign: "center",
    marginRight: theme.spacing(5),
  },
  container: {
    position: "relative",
    margin: theme.spacing(2),
    height: "auto !important",
    minHeight: "100%",
    paddingBottom: "15vh",
  },
  timer: {
    marginBottom: theme.spacing(1),
  },
  progressBar: {
    marginBottom: theme.spacing(1),
  },
  statusText: {},
  statusIcon: {
    marginTop: "5vh",
    marginBottom: "5vh",
    width: "35vw",
    height: "35vw",
    maxWidth: "200px",
    maxHeight: "200px",
    objectFit: "contain",
  },
  table: {
    marginTop: theme.spacing(1),
  },
  callBox: {
    position: "fixed",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "var(--c-white)",
    boxShadow: "0 0px 15px rgba(0, 0, 0, 0.25)",
  },
  callLink: {
    color: "#1A73E8",
  },
}))
enum OrderUIStates {
  Rejected,
  Pending,
  Confirmed,
  InPrep,
  Delivery,
  Done,
}
const OrderUIStatesProps = {
  [OrderUIStates.Pending]: {
    img: ImgOrderConfirmed,
    text: "Deine Bestellung wird in kürze bestätigt.",
    progress: 10,
  },
  [OrderUIStates.Confirmed]: {
    img: ImgOrderConfirmed,
    text: "Deine Bestellung ist bestätigt und wird in Kürze zubereitet.",
    progress: 25,
  },
  [OrderUIStates.InPrep]: {
    img: ImgOrderInPrep,
    text: "Deine Bestellung wird zubereitet...",
    progress: 50,
  },
  [OrderUIStates.Delivery]: {
    img: ImgOrderDelivery,
    text: "Deine Bestellung ist auf dem Weg zu dir.",
    progress: 75,
  },
  [OrderUIStates.Done]: {
    img: ImgOrderDone,
    text: "Deine Bestellung ist bei dir, guten Appetit!",
    progress: 100,
  },
}

const OrderStatusToUIMap = {
  [OrderStatus.Checkout]: OrderUIStates.Pending,
  [OrderStatus.Requested]: OrderUIStates.Pending,
  [OrderStatus.Validated]: OrderUIStates.Pending,

  [OrderStatus.Rejected]: OrderUIStates.Rejected,

  [OrderStatus.Accepted]: OrderUIStates.Confirmed,
  [OrderStatus.ReadyForPrep]: OrderUIStates.Confirmed,

  [OrderStatus.InPrep]: OrderUIStates.InPrep,
  [OrderStatus.ReadyForDelivery]: OrderUIStates.InPrep,

  [OrderStatus.InDelivery]: OrderUIStates.Delivery,

  [OrderStatus.Delivered]: OrderUIStates.Done,
}

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default function Status({}) {
  const [timer, setTimer] = useState("00:00")
  const [uiState, setUIState] = useState(OrderUIStates.Pending)
  const classes = useStyles()
  const [orderSummary, setOrderSummary] = useState(null)
  const [loadDate, setLoadDate] = useState(null)
  const [doneDate, setDoneDate] = useState(null)

  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])

  function updateOrderStatus(orderId) {
    firebase
      .functions()
      .httpsCallable("order_getStatus")({
        orderId: orderId,
      })
      .then(result => {
        setUIState(OrderStatusToUIMap[result.data.status])
      })
  }

  useEffect(() => {
    if (hasMounted) {
      const orderId = getParameterByName('o'); 
      console.log("oid:" + orderId)
      if (!orderId) return // TODO: show error
      firebase
        .functions()
        .httpsCallable("order_getSummary")({
          orderId: orderId,
        })
        .then(result => {
          console.log(result)
          setOrderSummary(result.data)
          setUIState(OrderStatusToUIMap[result.data.status])
        })

      setLoadDate(Date.now())

      const timer = setInterval(() => { updateOrderStatus(orderId) }, 5000)
      return () => clearTimeout(timer)
    }
  }, [hasMounted])

  useEffect(() => {
    if (loadDate) {
      const timer = setInterval(() => {
        setTimer(new Date(Date.now() - loadDate).toISOString().substr(14, 5))
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [loadDate])

  useEffect(() => {
    if (uiState === OrderUIStates.Done && !doneDate) setDoneDate(Date.now())
  }, [uiState])

  var statusIcon = OrderUIStatesProps[uiState].img
  var statusText = OrderUIStatesProps[uiState].text
  var progress = OrderUIStatesProps[uiState].progress
  var phoneLink = "tel:" + orderSummary?.restaurant.phone
  return (
    <Layout>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {orderSummary
              ? `Bestellung bei ${orderSummary.restaurant.name.de}`
              : "Bestellung wird geladen"}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box className={classes.container}>
        <Typography variant="h2" className={classes.timer}>
          {doneDate
            ? new Date(doneDate - loadDate).toISOString().substr(14, 5)
            : timer}
        </Typography>
        <LinearProgress
          variant={orderSummary ? "determinate" : "indeterminate"}
          value={progress}
          className={classes.progressBar}
        />
        {orderSummary ? (
          <React.Fragment>
            <Typography variant="body1" className={classes.statusText}>
              {statusText}
            </Typography>

            <Box display="flex" justifyContent="center">
              <img src={statusIcon} className={classes.statusIcon} />
            </Box>

            <Typography variant="h2" className={classes.timer}>
              Zusammenfassung
            </Typography>
            <TableContainer component={Box}>
              <Table className={classes.table} aria-label="spanning table">
                <TableBody>
                  {orderSummary?.lineItems.map(item => (
                    <TableRow key={item.itemId}>
                      <TableCell>{item.count}x</TableCell>
                      <TableCell>{item.name.de}</TableCell>
                      <TableCell align="right">
                        <PriceText price={item.price} />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={2}>
                      <b>Total</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>
                        <PriceText price={orderSummary?.total} />
                      </b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              className={classes.callBox}
              display="flex"
              justifyContent="center"
            >
              <a href={phoneLink} className={classes.callLink}>
                Gibt's ein Problem? Ruf uns an!
              </a>
            </Box>
          </React.Fragment>
        ) : null}
      </Box>
    </Layout>
  )
}
